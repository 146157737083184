@import '../../../App.scss';

// ------------- VARIABLES ------------- //
$parallax-section: 100%;
$transition-speed: 2.5s;
$parallax-offset: 30vh;

.parallax {
  background-color: $background;
  min-height: $parallax-section;
}

.scrollDown {
  position: fixed;
  bottom: 80px;
  left: 40px;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  color: black;
  z-index: 20;

  &:after {
    content: "";
    width: 2px;
    height: 64px;
    background: black;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -80px;
  }
}