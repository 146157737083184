@use 'sass:math';
@import '../../../App.scss';

$nav-list-height: 2px;
$nav-list-width: 25px;
$nav-active-element-width: 40px;
$nav-list-border-radius: 20px;

#fp-nav {
  overflow: hidden;
  position: fixed;
  z-index: 20;
  width: 40px;
  left: 40px;
  top: 50%;
  opacity: 1;
  transform: translate3d(0, -50%, 0);
}
#fp-nav ul {
  margin: 0;
  padding: 0;
}
#fp-nav ul li {
  display: block;
  height: $nav-list-height;
  margin: 0 0 30px;
}
#fp-nav ul li div.active {
  width: $nav-active-element-width;
}
#fp-nav ul li div {
  border-radius: $nav-list-border-radius;
  width: $nav-list-width;
  height: 100%;
  background: $secondary;
  display: block;
  transition: width 0.3s ease-in-out;
  position: relative;
  text-decoration: none;
}
