#mouse-trail {
    z-index: 100;
    background: none;
    position: absolute;
    
    canvas {
        z-index: 100;
        background: none;
        position: absolute;
    }
}