@import '../../App.scss';

.skills-bar .bar {
    margin: 20px 0;
}
.skills-bar .bar:first-child {
    margin-top: 0px;
}
.skills-bar .bar .info {
    margin-bottom: 5px;
}
.skills-bar .bar .info span {
    opacity: 0;
    animation: showText 0.5s 1s linear forwards;
}
@keyframes showText {
    100% {
        opacity: 1;
    }
}
.skills-bar .bar .progress-line {
    position: relative;
    height: 10px;
    background: $background;
    border-radius: 10px;
    -webkit-transform: scaleX(0);
    -webkit-transform-origin: left;
    transform: scaleX(0);
    transform-origin: left;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 1px rgba(255, 255, 255, 0.8);
    animation: animate 1s cubic-bezier(1, 0, 0.5, 1) forwards;
}
.bar .progress-line span {
    height: 100%;
    width: 0%;
    background: $primary;
    position: absolute;
    border-radius: 10px;
    -webkit-transform: scaleX(0);
    -webkit-transform-origin: left;
    transform: scaleX(0);
    transform-origin: left;
}
.bar .progress-line span.animation {
    animation: animate 1s 1s cubic-bezier(1, 0, 0.5, 1) forwards;
}

@keyframes animate {
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}