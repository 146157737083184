// ------------- MIXINS ------------- //
@mixin transition($time, $property: all, $easing: ease-in) {
    transition: $property $time $easing;
}

$primary: #318b99;
$secondary: black;
$secondary-variant: rgb(100 116 139);
$background: white;
$on-background: black;

@keyframes opacity {
    0%    {opacity: 0; transform: translateY(-1.25rem);}
    100%  {opacity: 1; transform: translateY(0)}
}  

.opacity-enter {
    animation-name: opacity;
    animation-duration: 1s;
}