@use 'sass:math';
@import '../../../App.scss';

// ------------- VARIABLES ------------- //
$parallax-offset: 30vh;
$content-offset: 40vh;
$transition-speed: 2.5s;

.background {
  background-color: $background;
  box-shadow: 0px 12px 8px 1px rgba(0, 0, 0, .2);
  overflow: hidden;
  will-change: transform;
  backface-visibility: hidden;
  height: 100%;
  position: fixed;
  width: 100%;
  @include transition($transition-speed, all, cubic-bezier(0.22, 0.44, 0, 1));
  .content-wrapper {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    > * {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $background;
  }
}

.content-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  will-change: transform;
  backface-visibility: hidden;
  @include transition($transition-speed - .5, all, cubic-bezier(0.22, 0.44, 0, 1));
    > * {
      height: 100%;
    }
}


// ------------- SET TRANSFORM VALUES ------------- //

.background.up-scroll {
  .content-wrapper {
    -webkit-transform: translateY($parallax-offset);
    transform: translateY($parallax-offset);
  }
}

.background.down-scroll {
  -webkit-transform: translate3d(0,-(100vh + $parallax-offset),0);
  transform: translate3d(0,-(100vh + $parallax-offset),0);
  .content-wrapper {
    -webkit-transform: translateY($content-offset);
    transform: translateY($content-offset);
  }
}

