@import '../../../App.scss';

.logo-loading-container {
    background-color: black;
    position: fixed;
}
.loading-animation {
    width: 15vh;
    animation: loading 7s infinite;
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        transform: rotate(0);
    }
    50% {
        -webkit-transform: rotate(-360deg);
        -moz-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
    100% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        transform: rotate(0);
    }
}

.inner-container {
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

.loading-menu-bg {
    min-height: 20px;
    padding: 15px;
    display: flex;
    align-content: center;
    align-items: center;
    background-color: $background;
  
    &.top {
      height: calc(60%);
      margin-top: calc((130% / 1.73)* -1);
      padding-top: calc((60% / 1.73) * 2);
      transform: skewY(-45deg) translateY(-100%);
    }
    &.middle {
      height: calc(60%);
      padding-top: calc((60%  / 1.73) * 2);
      transform: skewY(-45deg) translateX(calc(70% + calc((60%  / 1.73)* -1) + calc((60%  / 1.73) * 2)));
    }
    &.bottom {
      height: calc(60%);
      margin-bottom: calc((60%  / 1.73)* -1);
      padding-bottom: calc((60%  / 1.73) * 2);
      transform: skewY(-45deg) translateY(100vh);
    }
  }
  