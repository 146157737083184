@import '../../../App.scss';

//VARIABLES
//COLORS
$black-20:rgba(0,0,0,0.2);
$white-100:rgba(255,255,255,1);
$white-20:rgba(255,255,255,0.2);

//ANIMATIONS
$slide: all 0.3s cubic-bezier(.55,0,.1,1);


.navbar-container {
  position: absolute;
  top: 0px;
  background-color: transparent;
  margin: 0 auto;
  width: 100vw;
  z-index: 100;
  height: 12%;
  margin: 0 auto;
  overflow: hidden;
  -webkit-transform: scale(1);
  transform: scale(1);

  @media screen and (min-width: 640px) {
    height: 8%;
  }
}

.menu-trigger, .close-trigger {
  position: absolute;
  top: 15px;
  right: 20px;
  display: block;
  max-width: 42px;
  max-height: 42px;
  width: 5vh;
  height: 5vh;
  cursor: pointer;
  z-index: 333;

  @media screen and (min-width: 550px) {
    top: 25px; 
  }
}

.close-trigger {
  z-index: 5;
  top: 42px;
}

.menu-trigger-bar {
  display: block;
  width: 100%;
  height: 3px;
  background-color: $secondary;
  margin-bottom: 6px;
  transform: rotate(-45deg);
  position: relative;

  @media screen and (min-width: 550px) {
    height: 4px;
  }
  
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 0%;
    height: 100%;
    background-color: $black-20;
    transition: $slide;
  }
  
  &.top {
    width: 50%;
  }
  &.middle {
    &:before {
      left: auto;
      right: 0;
    }
  }
  &.bottom {
    width: 50%;
    margin-left: 50%;
  }
}

.close-trigger-bar {
  display: block;
  width: 100%;
  height: 4px;
  background-color: $white-100;
  position: relative;
  
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 0%;
    height: 100%;
    background-color: $white-20;
    transition: $slide;
  }
  
  &.left {
    transform: translateX(100px) translateY(-100px) rotate(-45deg);
  }
  &.right {
    transform: translateX(-100px) translateY(-100px) rotate(45deg);
    top: -3px;
  }
}

.inner-container, .menu-container {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.inner-container {
  z-index: 20;
}
.menu-container {
  display: flex;
}
.menu {
  display: block;
  width: 100%;
  padding: 0% 20%;
  
  li {
    text-align: center;
    display: block;
    padding: 15px 0;

    & div {
      text-decoration: none;
      color: $white-100;
      display: inline-block;
      padding: 10px 0;
      position: relative;
      
      &:hover {
        &:before {
          opacity: 1;
          transform: translateX(0px);
        }
      }
  
      &.active {
        &:before {
          opacity: 1;
          transform: translateX(0px);
        }
      }
      
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: -10%;
        bottom: 0;
        width: 120%;
        height: 4px;
        opacity: 0;
        background-color: $primary;
        transform: translateX(-30%);
        transition: $slide;
      }
    }
  }
}

.menu-bg {
  min-height: 20px;
  padding: 15px;
  display: flex;
  align-content: center;
  align-items: center;
  background-color: $secondary;

  &.top {
    height: calc(60%);
    margin-top: calc((130% / 1.73)* -1);
    padding-top: calc((60% / 1.73) * 2);
    transform: skewY(-45deg) translateY(-100%);
  }
  &.middle {
    height: calc(60%);
    padding-top: calc((60%  / 1.73) * 2);
    transform: skewY(-45deg) translateX(calc(70% + calc((60%  / 1.73)* -1) + calc((60%  / 1.73) * 2)));
  }
  &.bottom {
    height: calc(60%);
    margin-bottom: calc((60%  / 1.73)* -1);
    padding-bottom: calc((60%  / 1.73) * 2);
    transform: skewY(-45deg) translateY(100vh);
  }
}