@import '../../App.scss';

.btn {
    padding: 15px;
    text-decoration: none;
    text-align: center;
    transition: all .5s ease; 
  }
  
  .btn-clipped {
    color: #fff;
    padding-right: 0;
    background-color: $primary;
    -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 70%, 90% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 50%, 80% 100%, 0 100%);
  
  }
  
  .btn-clipped:hover { 
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 100%);  
  }
  
  .btn-clipped:after {
    content: "";
    width: 20px;
    display: inline-block;
    position: relative;
    right: -55px;
    transition: all 0.2s ease;
  }
  
  .btn-clipped:hover:after {
    right: 0px;
  }