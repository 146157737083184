@import '../../App.scss';

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #B4B4B4 $background;
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: #B4B4B4;
  border-radius: 20px;
  border: 5px solid $background;
}

.responsibility:before { 
  content: '/';
  margin-left: -20px;
  margin-right: 10px; 

  @media screen and (min-width: 640px) {
    margin-left: -30px; 
  }
} 